import { render, staticRenderFns } from "./previewTemplate.vue?vue&type=template&id=5b50aa52&"
import script from "./previewTemplate.vue?vue&type=script&lang=js&"
export * from "./previewTemplate.vue?vue&type=script&lang=js&"
import style0 from "./previewTemplate.vue?vue&type=style&index=0&id=5b50aa52&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Java\\healthy\\healthy-frame-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5b50aa52')) {
      api.createRecord('5b50aa52', component.options)
    } else {
      api.reload('5b50aa52', component.options)
    }
    module.hot.accept("./previewTemplate.vue?vue&type=template&id=5b50aa52&", function () {
      api.rerender('5b50aa52', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/healthy/tCheckStation/previewTemplate.vue"
export default component.exports