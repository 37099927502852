var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "previewTemplateCheck",
      attrs: {
        title: "预览",
        "mask-closable": false,
        fullscreen: _vm.isFullscreen,
        width: _vm.modalWidth,
        "footer-hide": true,
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            position: "relative",
            "margin-right": "30px",
            display: "flex",
            "justify-content": "space-between",
            cursor: "pointer",
          },
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c("p", [_vm._v("模板预览")]),
          _c("Icon", {
            attrs: { type: "md-expand", size: "24", color: "#ccc" },
            on: { click: _vm.changeFull },
          }),
        ],
        1
      ),
      _c("iframe", {
        staticClass: "pdfTemplate",
        staticStyle: { width: "100%", height: "calc(100% - 10px)" },
        attrs: { id: "printPdf", src: _vm.previewHtmlUrl, frameborder: "0" },
      }),
      _c(
        "Spin",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { fix: "" },
        },
        [
          _c("div", { staticClass: "demo-spin-icon-load" }, [
            _c("i", { staticClass: "iconfont icon-loader--line" }),
          ]),
          _c("div", { staticStyle: { "font-size": "16px" } }, [
            _vm._v("Loading..."),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }